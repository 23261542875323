import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Components
import Layout from '../../components/Layout';

// Images
import NewsImg from '../../img/new_bn.jpg';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('zh');

const News = () => {
  const [query, setQuery] = useState('');

  const data = useStaticQuery(graphql`
    {
      newsGroup: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/news/**.md" } }) {
        edges {
          node {
            frontmatter {
              title
              newsCategory
              thumbnail {
                publicURL
              }
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  const filteredNews = data.newsGroup.edges.filter((edge) => {
    if (query) return edge.node.frontmatter.newsCategory === query;
    return null;
  });
  const news = query ? filteredNews : data.newsGroup.edges;

  return (
    <Layout>
      <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
        <div className="container">
          <div id="breadcrumb">
            <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
              <div className="col-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb small text-white pt-3 pb-2 bg-js" style={{ backgroundColor: '#1169ac' }}>
                    <li className="breadcrumb-item">
                      <Link to="/" style={{ color: '#fff' }}>
                        {LANG.首頁}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">{LANG.新聞中心}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="case" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12 text-left">
              <p className="h3">
                <strong>{LANG.新聞中心}</strong>
              </p>
              <br />
              <p>{LANG.新聞中心內文}</p>
            </div>
            <div id="BOX" className="col-12">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-4">
        <div className="portfolio-div">
          <div className="portfolio">
            <div className="row col-12 my-4">
              <div className="categories-grid wow fadeInLeft">
                <nav className="categories text-center">
                  <ul className="portfolio_filter m-0">
                    <li>
                      <a
                        className={query === '' ? 'active' : null}
                        onClick={() => setQuery('')}
                        style={{ cursor: 'pointer' }}
                      >
                        All
                      </a>
                    </li>
                    {['公司快訊', '特價商品', '媒體報導', '產業新知'].map((el) => {
                      return (
                        <li style={{ cursor: 'pointer' }} key={el}>
                          <a onClick={() => setQuery(el)} className={query === el ? 'active' : null}>
                            {LANG[el]}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="portfolio_container row">
              {news.map(
                ({
                  node: {
                    frontmatter: { title, thumbnail },
                    excerpt,
                    fields: { slug },
                  },
                }) => {
                  return (
                    <div className="col-6 col-md-4 col-lg-3 mb-3 com" key={slug}>
                      <div className="card text-secondary">
                        <div className="hover-zoom rounded-top" style={{ backgroundImage: `url(${NewsImg})` }}>
                          {thumbnail && <img src={thumbnail.publicURL} className="card-img-top" />}
                        </div>
                        <div className="card-body">
                          <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                            {title}
                          </h5>
                          <p className="card-text text_limit2">{excerpt}</p>
                          <Link to={`/news/${slug}`} className="btn-sm btn-primary">
                            {LANG.閱讀全文}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default News;
